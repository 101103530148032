<template lang="pug">
#root
  md-app(:md-scrollbar="false").app
    md-app-toolbar.flex.bg-white.md-toolbar
      md-button.md-icon-button.mobile(@click="menuVisible = !menuVisible")
        md-icon menu
      img.header-logo(src="@/assets/images/logo-36Kr.png")
      md-tabs.tabs(md-sync-route)
        md-tab(md-label="游戏开发者峰会", to="/", exact)
        md-tab(md-label="手游出海破新界", to="/campaign/tg2021")
        md-tab(md-label="最新产品与趋势", to="/campaign/gml")
        md-tab(md-label="成就全球强品牌", to="/campaign/competitive_brand")
        md-tab(md-label="跨境电商新篇章", to="/campaign/think_of_retail")
      .spacer
      md-button.md-dense.md-raised.md-primary.padding-btn.pc(href="/static/%E3%80%8A2021%20%E4%B8%AD%E5%9B%BD%E5%85%A8%E7%90%83%E5%8C%96%E5%93%81%E7%89%8C%2050%20%E5%BC%BA%E3%80%8B%E6%8A%A5%E5%91%8A.pdf")
        | 下载《2021 中国全球化品牌 50 强》报告
      md-button.md-dense.md-raised.md-primary.padding-btn.mobile(href="/static/%E3%80%8A2021%20%E4%B8%AD%E5%9B%BD%E5%85%A8%E7%90%83%E5%8C%96%E5%93%81%E7%89%8C%2050%20%E5%BC%BA%E3%80%8B%E6%8A%A5%E5%91%8A.pdf")
        | 下载品牌报告
    md-app-drawer(:md-active.sync="menuVisible")
      md-toolbar.md-transparent(md-elevation="0")
        span.md-title
          | 导航
      md-list
        md-list-item(to="/", exact)
          md-icon videogame_asset
          span.md-list-item-text
            | 游戏开发者峰会
        md-list-item(to="/campaign/tg2021")
          md-icon games
          span.md-list-item-text
            | 手游出海破新界
        md-list-item(to="/campaign/gml")
          md-icon trending_up
          span.md-list-item-text
            | 最新产品与趋势
        md-list-item(to="/campaign/competitive_brand")
          md-icon public
          span.md-list-item-text
            | 成就全球强品牌
        md-list-item(to="/campaign/think_of_retail")
          md-icon shopping_cart
          span.md-list-item-text
            | 跨境电商新篇章
    md-app-content.app-container
      .container.cover-container
        img.cover(src="@/assets/images/cover-campaign-brand-desktop.png")
        img.cover-mobile(src="@/assets/images/cover-campaign-brand-mobile.png")
        p.cover-text
          | 2021 品牌领袖圆桌会聚焦品牌发展新机会，基于中国全球化品牌现状分享最佳实践案例，助力中国品牌跳出窠臼、开拓思路，加速发展、弯道超车，全面转型成为下一个全球化强品牌。
      h2.header-text.bg-grey
        | 收看圆桌会精彩内容
      .videos-container.bg-grey#videos(ref="videos")
        .videos
          a(href="/videos/6", target="_blank")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/6.jpg")
              md-card-content
                .video-content-text
                  | 视频
                h3
                  | 智造新机会，成就全球强品牌
                a.span-btn(href="/videos/6", target="_blank")
                  md-icon.span-icon.md-primary play_arrow
                  span.span-text
                    | 播放
          a(href="/videos/7", target="_blank")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/7.jpg")
              md-card-content
                .video-content-text
                  | 视频
                h3
                  | 2021 年 BrandZ™ 中国全球化品牌 50 强
                a.span-btn(href="/videos/7", target="_blank")
                  md-icon.span-icon.md-primary play_arrow
                  span.span-text
                    | 播放
          a(href="/videos/8", target="_blank")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/8.jpg")
              md-card-content
                .video-content-text
                  | 视频
                h3
                  | 探索机会，在新兴市场引领未来
                a.span-btn(href="/videos/8", target="_blank")
                  md-icon.span-icon.md-primary play_arrow
                  span.span-text
                    | 播放
          a(href="/videos/9", target="_blank")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/9.jpg")
              md-card-content
                .video-content-text
                  | 视频
                h3
                  | 抓住机会，在变局中贴近消费者
                a.span-btn(href="/videos/9", target="_blank")
                  md-icon.span-icon.md-primary play_arrow
                  span.span-text
                    | 播放
      .arrow-container.bg-grey
        .arrow.arrow-back(@click="videosBack")
          div.arrow-icon(:class="{active: !isVideoToEnd}")
        .arrow.arrow-forward(@click="videosForward")
          div.arrow-icon(:class="{active: isVideoToEnd}")
      h2.header-text.bg-grey
        | 收听圆桌会精华分享
      .videos-container.bg-grey#audios
        .audios
          a(@click="playAudio(0)")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/7.jpg")
              md-card-content
                .video-content-text
                  | 音频
                h3
                  | 2021 年 BrandZ™ 中国全球化品牌 50 强
                a.span-btn(@click="playAudio(0)")
                  md-icon.span-icon.md-primary play_arrow
                  span.span-text
                    | 播放
          a(@click="playAudio(1)")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/8.jpg")
              md-card-content
                .video-content-text
                  | 音频
                h3
                  | 探索机会，在新兴市场引领未来
                a.span-btn(@click="playAudio(1)")
                  md-icon.span-icon.md-primary play_arrow
                  span.span-text
                    | 播放
          a(@click="playAudio(2)")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/9.jpg")
              md-card-content
                .video-content-text
                  | 音频
                h3
                  | 抓住机会，在变局中贴近消费者
                a.span-btn(@click="playAudio(2)")
                  md-icon.span-icon.md-primary play_arrow
                  span.span-text
                    | 播放
      h2.header-text
        | 更多中国全球化品牌分析与洞察
      .container#articles
        carousel(:perPage="1", :autoplay="false", :loop="true")
          slide
            .slide-container
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/Dpi2Ontn4n43LSRwKLPucw')")
                img.article-image(src="@/assets/images/posts/post_15.jpg")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 如何拓展全球新兴市场新机遇？
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/SqfuSjahoBwhNXlcis5e4g')")
                img.article-image(src="@/assets/images/posts/post_14.png")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 如何成为下一个全球强品牌？
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/U4pvT9csvOt8X7AOfTymtw')")
                img.article-image(src="@/assets/images/posts/post_13.png")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 【重磅】凯度×Google 发布 BrandZ™ 中国全球化品牌 50 强名单
          slide
            .slide-container
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/Tqh6dZisl8tfCMnek6i6-g')")
                img.article-image(src="@/assets/images/posts/post_12.jpg")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 凯度×Google | 赢在未来，聚焦新兴市场中国明星品牌 20 强
      md-divider
      .container#downloads
        .flex-section.flex-reverse
          img.section-image(src="@/assets/images/section-cover-brandz.png")
          .section-content
            h2.section-header.my-4.report-h2
              | 下载《2021 中国全球化品牌 50 强》报告
            .section-description
              | 获取中国全球化品牌最新洞察，助力品牌塑造“有意义的”、“差异化的”、富于“突出性”的定位，打造强大、坚韧、高质量增长的全球化品牌。
            md-button.md-dense.md-primary.md-raised.lg-btn(@click="openUri('/static/%E3%80%8A2021%20%E4%B8%AD%E5%9B%BD%E5%85%A8%E7%90%83%E5%8C%96%E5%93%81%E7%89%8C%2050%20%E5%BC%BA%E3%80%8B%E6%8A%A5%E5%91%8A.pdf')")
              | 点击下载
      .footer
        p
          | 如果您也希望尝试谷歌广告解决方案拓展您的海外业务，现在即可致电谷歌专家。
          br.mobile
          | 新用户还可领取最高 400 元优惠券，助力轻松开启谷歌广告之旅。
          br
          | 欢迎拨打：400 - 819 - 1244
          br
          | 工作日：9:00 - 18:00
        md-divider
        img.footer-logo(src="@/assets/images/logo-36Kr-grey.png")
  .hidden(:class="{ 'share-mobile': isShare }", @click="() => { isShare = false; }")
    img.share-img(src="~@/assets/images/share.png")
  .music-player.hidden(:class="{ 'show-player': showPlayer }")
    md-icon.close-icon(@click.native="closePlayer") close
    audio-player(ref="audioPlayer", :audio-list="audioList.map(elm => elm.url)", theme-color="#ff2929", :is-loop="true")
  .shares
    .share-icon-container(@click="share")
      md-icon.share-icon share
      .hidden(:class="{ 'share-pc': isShare }", @click="() => { isShare = false; }")
        img(src="~@/assets/images/share-pc.png", width='150px')
    .share-icon-container(@click="like")
      md-icon.share-icon(:class="{ active: isActive }") favorite
</template>

<script>
export default {
  name: "CompetitveBrand",
  components: {},
  data() {
    return {
      isActive: false,
      isStar: false,
      isShare: false,
      isVideoToEnd: false,
      showPlayer: false,
      menuVisible: false,
      audioList: [
        {
          name: "【Doreen】2021年 BrandZ™ 中国全球化品牌 50 强",
          url: "/static/audios/1.mp3",
        },
        {
          name: "【OPPO】探索机会，在新兴市场引领未来",
          url: "/static/audios/2.mp3",
        },
        {
          name: "【Xiaomi】抓住机会，在变局下贴近消费者",
          url: "/static/audios/3.mp3",
        },
      ],
    };
  },
  methods: {
    like() {
      this.$pushEvent("like", {});
      this.isActive = !this.isActive;
    },
    star() {
      this.isStar = !this.isStar;
    },
    share() {
      this.$pushEvent("share", {});
      this.isShare = !this.isShare;
    },
    openUri(uri) {
      this.$pushEvent("openUri", { url: uri }).then(() => {
        const ua = navigator.userAgent.toLowerCase();
        if (
          (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
          ua.indexOf("micromessenger") !== -1
        ) {
          window.location.assign(uri);
        } else {
          window.open(uri, "_blank");
        }
      });
    },
    videosBack() {
      this.isVideoToEnd = false;
      const ele = this.$refs.videos;
      ele.scrollLeft -= 500;
    },
    videosForward() {
      this.isVideoToEnd = true;
      const ele = this.$refs.videos;
      ele.scrollLeft += 500;
    },
    playAudio(idx) {
      this.$pushEvent("audio", { idx: idx });
      this.$refs.audioPlayer.pause();
      this.showPlayer = true;
      while (this.$refs.audioPlayer.currentPlayIndex !== idx) {
        this.$refs.audioPlayer.playNext();
      }
      this.$nextTick(() => {
        this.$refs.audioPlayer.play();
      });
    },
    closePlayer() {
      this.showPlayer = false;
      this.$refs.audioPlayer.pause();
    },
    beforePlay(next) {
      this.$refs.audioPlayer.play();
      next();
    },
  },
  mounted() {
    this.$pushEvent("visit", { route: "home" });

    // This is a hack to Chromium bug
    if (window.location.hash.length > 0) {
      const hash = window.location.hash.substring(1);
      document.getElementById(hash).scrollIntoView();
      // hack to Safari bug
      const ua = navigator.userAgent.toLowerCase();
      if (
        (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
        ua.indexOf("micromessenger") !== -1
      ) {
        window.setTimeout(() => {
          document.getElementById(hash).scrollIntoView();
        }, 100);
      }
    }
    if (window.screen.width > 1500) {
      const ele = this.$refs.videos;
      let pos = { top: 0, left: 0, x: 0, y: 0 };

      const mouseDownHandler = (e) => {
        // e.preventDefault();
        // ele.style.cursor = "grabbing";
        ele.style.userSelect = "none";

        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          x: e.clientX,
          y: e.clientY,
        };

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };

      const mouseMoveHandler = (e) => {
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
      };

      const mouseUpHandler = () => {
        // ele.style.cursor = "grab";
        ele.style.removeProperty("user-select");

        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
      };

      ele.addEventListener("mousedown", mouseDownHandler);
    }
  },
};
</script>

<style lang="stylus" scoped>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

h2 {
  text-align: center;
}

h3 {
  font-size: 15px;
}

a {
  cursor: pointer;
}

.report-h2 {
  font-size: 19px;
}

.tabs {
  @media screen and (min-width: 1280px) {
    display: block;
  }
  display: none;
}

.md-toolbar {
  min-height: 48px;
}

.md-toolbar .md-tabs {
  margin: 0 !important;
}

.pc {
  @media screen and (min-width: 1280px) {
    display: block;
  }
  display: none;
}

.mobile {
  @media screen and (min-width: 1280px) {
    display: none;
  }
  display: block;
}

.arrow-container {
  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content center
    align-items center
    position: relative;
    height: 50px;
  }
  display: none;
}

.arrow {
  @media screen and (min-width: 1280px) {
    top: 10px;
    z-index: 9;
    display: block;
    cursor: pointer;
  }
}

.arrow-back {
  margin-right 10px;
}

.arrow-forward {
  margin-left 10px;
}

.arrow-icon {
  background-color #cccccc;
  width: 10px;
  height: 10px;
  border-radius 5px;
  &.active {
    background-color #000000;
  }
}

.share-img {
  width: 200px;
  position: absolute;
  right: 0;
  top: 0;
}

.hidden {
  display: none;
}

.show-player {
  display: flex;
}

.share-pc {
  @media screen and (min-width: 1024px) {
    display: block;
    position: absolute;
    left: -150px;
    top: -90px;
  }
}

.share-mobile {
  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.3;
  }
}

.shares {
  position: fixed;
  background-color: #fff;
  right: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 10px 0 0 10px;

  @media screen and (max-width: 1280px) {
    top: 30%;
  }
}

.share-icon-container {
  margin: 10px;
  cursor: pointer;
}

.share-icon {
  color: #000 !important;
}

.close-icon {
  margin: 0 0 0 auto;
  cursor: pointer;
}

.active {
  color: #ff0000 !important;
}

.star {
  color: #ffcc00 !important;
}

.my-4 {
  margin-bottom: 20px;
}

.my-8 {
  margin-bottom: 40px;
}

.app {
  max-width: 100vw;
  max-height: 100vh;
  position: absolute;
}

.app-container {
  max-width: 100vw;
  max-height: 100vh;
}

.flex {
  display: flex;
}

.spacer {
  flex: 1;
}

.header-logo {
  height: 24px;
}

.padding-btn {
  padding: 5px 10px;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.cover-container {
  // @media screen and (min-width: 1280px) {
  //   padding: 5vh 0;
  // }
}

.cover {
  display: none;
  width: 100vw;
  margin: auto;

  @media screen and (min-width: 1280px) {
    display: block;
  }
}

.cover-text {
  padding 0 20px;
  line-height: 1.8;
  text-align: left;
  @media screen and (min-width: 1280px) {
    padding 0 50px;
    font-size: 20px;
  }
}

.header-text {
  padding 16px 20px;
  line-height: 1.8;
  text-align: left;
  @media screen and (min-width: 1280px) {
    padding 16px 50px;
    font-size: 20px;
  }
  margin: 0;
}

.cover-mobile {
  display: block;
  margin: 0 auto;

  @media screen and (min-width: 1280px) {
    display: none;
  }
}

.md-content {
  padding: 0;
}

.videos-container {
  width: 100vw;
  display: flex;
  position: relative;

  @media screen and (min-width: 1280px) {
    display: block;
    overflow-x: scroll;
    cursor: grab;
  }

  overflow-x: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.videos-container::-webkit-scrollbar {
  display: none;
}

.videos {
  width: 90%;
  margin: 0 auto;

  @media screen and (min-width: 1280px) {
    width: 2200px; // 440px * 5
    margin: 0 auto 0 0;
  }

  @media screen and (max-width: 1280px) {
    h3 {
      margin 0;
    }
  }
}

.audios {
  width: 90%;
  margin: 0 auto;

  @media screen and (min-width: 1280px) {
    width: 1600px; // 440px * 5
    margin: 0 auto 0 0;
  }

  @media screen and (max-width: 1280px) {
    h3 {
      margin 0;
    }
  }
}

.video-card {
  display: block;
  margin: 20px auto;
  padding: 0 0 10px 0;
  width: 90%;
  cursor pointer;

  @media screen and (min-width: 1280px) {
    width: 400px;
    display: inline-block;
    margin: 20px;
  }

  border-radius: 32px 32px 32px 32px;

  h3 {
    font-weight normal;
    font-size 1.1rem;
    margin: 0.5rem 0;
    height: 40px;
  }

  .span-btn {
    display block
    margin 8px 0 0 -5px;
  }
}

.video-content-text {
  color: rgb(117, 117, 117);
  margin-bottom 5px;
}

.article-content-title {
  color: rgb(117, 117, 117);
}

.bg-grey {
  background-color: #f2f2f2;
}

.bg-white {
  background-color: #fff;
}

.md-card-media img {
  border-radius: 32px 32px 0 0;
}

.span-btn:hover {
  text-decoration: none;
}

.span-text {
  display: inline-block;
  line-height: 24px;
  vertical-align: middle;
}

.slide-container {
  @media screen and (min-width: 1280px) {
    width: 800px;
  }

  width: 90%;
  margin: 0 auto;
}

.article-card {
  display: flex;
  cursor: pointer;
  margin: 20px 0;
}

.article-image {
  @media screen and (min-width: 1024px) {
    width: 200px;
    height: calc(200px / 4 * 3);
    border-radius: 32px;
    margin: 0 30px 0 0;
  }

  width: 140px;
  height: calc(140px / 4 * 3);
  border-radius: 16px;
  margin: 0 15px 0 0;
}

.article-content {
  flex: 4;
}

.flex-section {
  display: flex;
  padding: 40px 0;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    padding: 60px 0;
    justify-content center;
    align-items center;
    max-width 1000px;
    margin: 0 auto;
  }

  flex-direction: column;
}

.section-description {
  margin: 0 20px 10px 20px;

  //@media screen and (min-width: 1280px) {
  //  padding: 0 110px;
  //}
}

.section-image {
  max-width: 90%;

  @media screen and (min-width: 1280px) {
    max-width: 50%;
  }

  width: auto;
  height: auto;
  justify-self: flex-start;
  align-self: flex-start;
  margin: 0 auto;
}

.section-content {
  @media screen and (min-width: 1280px) {
    width: 480px;
    padding: 0 40px;
  }

  padding: 0 10px;
  margin: auto 0;
}

.lg-btn {
  width: 220px;
  margin: 20px auto;
  display block;
}

.flex-reverse {
  @media screen and (min-width: 1280px) {
    flex-direction: row-reverse;
  }

  flex-direction: column;
}

.footer {
  background-color: #f5f5f5;
  padding: 20px;

  .footer-logo {
    margin: 20px 0 0 0;
    // margin: 20px auto 0 auto; 如果需要左右居中，使用此行
    height: 20px;
    display: block;
  }
}

.music-player {
  position: fixed;
  background-color: #fff;
  left: 10px;
  bottom: 10px;
  flex-direction: column;
  width: calc(100% - 20px);
  padding: 5px 10px 10px 10px;
  border: 1px solid;
  border-radius: 10px;
}
</style>
